@import "../partials/variables";

$color_facebook: #3b5998;
$color_twitter: #55acee;
$color_rss: #ff6600;
$color_youtube: #bb0000;
$color_pinterest: #cb2027;
$color_vimeo: #aad450;
$color_instagram: #125688;
$color_linkedin: #0e76a8;

$code_facebook: '\e903';
$code_twitter: '\e909';
$code_rss: '\e907';
$code_youtube: '\e90b';
$code_pinterest: '\e906';
$code_vimeo: '\e90a';
$code_instagram: '\e904';
$code_linkedin: '\e905';

$sf_icon_height: 20px;
$sf_icon_color: #5A5A5A;
$sf_block_height: 40px;
$sf_border_width: 2px;
$sf_border_color: $color_primary;

$sf_block_bg: false;
$sf_circle: false;

.block-social {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  .title {
    font-weight: 500;
    color: #5A5A5A;
    margin-left: 10px;
  }
  .social-links {
  }
  li {
    position: relative;
    height: $sf_block_height;
    width: $sf_block_height;
    display: inline-block;
    transition: $transition border-radius, $transition background-color;
    @if $sf_circle {
      border-radius: 50%;
      border-width: $sf_border_width;
      border-style: solid;
    }
    &:before {
      display: block;
      text-align: center;
      font-family: $sld_font_icon;
      font-size: $sf_icon_height;
      color: $sf_icon_color;
      @if $sf_circle {
        line-height: $sf_block_height - ($sf_border_width * 2);
      }
      @else {
        line-height: $sf_block_height;
      }
    }
    &:hover {
      border-radius: 50%;
    }
    a {
      display: block;
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;
      &:hover {
        color: transparent;
      }
    }
  }

  .facebook {
    @if $sf_block_bg {
      background-color: $color_facebook;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_facebook;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_facebook;
    }
  }
  .twitter {
    @if $sf_block_bg {
      background-color: $color_twitter;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_twitter;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_twitter;
    }
  }
  .rss {
    @if $sf_block_bg {
      background-color: $color_rss;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_rss;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_rss;
    }
  }
  .youtube {
    @if $sf_block_bg {
      background-color: $color_youtube;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_youtube;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_youtube;
    }
  }
  .pinterest {
    @if $sf_block_bg {
      background-color: $color_pinterest;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_pinterest;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_pinterest;
    }
  }
  .vimeo {
    @if $sf_block_bg {
      background-color: $color_vimeo;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_vimeo;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_vimeo;
    }
  }
  .instagram {
    @if $sf_block_bg {
      background-color: $color_instagram;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_instagram;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_instagram;
    }
  }
  .linkedin {
    @if $sf_block_bg {
      background-color: $color_linkedin;
    }
    @if $sf_circle {
      @if $sf_block_bg {
        border-color: $color_linkedin;
      }
      @else {
        border-color: $color_primary;
      }
    }
    &:before {
      content: $code_linkedin;
    }
  }
}
