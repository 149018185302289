$toastContainerTop: 20px;
$toastContainerLeft: auto;
$toastContainerRight: 20px;
$toastContainerBottom: auto;
$toastWidth: 250px;
$bgColor: $color_secondary;
$colorText: #ffffff;
$boxShadow: 5px 5px 10px #d8d8d8;
$transitionOut: 500ms;

.sld-toast-container {
    position: fixed;
    z-index: 3000;
    top: $toastContainerTop;
    left: $toastContainerLeft;
    right: $toastContainerRight;
    bottom: $toastContainerBottom;
    width: 250px;
}

.sld-toast {
    padding: 6px 12px;
    background-color: $bgColor;
    color: $colorText;
    box-shadow: $boxShadow;
    height: 100%;
    margin-bottom: 10px;
    transition: $transitionOut margin-bottom,
                $transitionOut margin-top,
                $transitionOut opacity;
}

.sld-toast-remove {
    margin-bottom: 0;
    opacity: 0;
}