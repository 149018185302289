
@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function rem($pxValue) {
  $remValue: ($pxValue / 16) + rem;
  @return $remValue;
}

//---------- THEME COLOR
$color_text:        #000; // den
$color_text_bold:   #000; // den dam
$color_text_light:  #4A4A4A; // den nhat
$color_green:       #5a9e74; // xanh la
$color_gray:        #f7f7f7; // xam
$color_border:      #c7c7c7; // border
$color_box_bg:      #ffffff; // mau box
$color_pink:        #fd6282; // hong
$color_blue:        #007ad1; // xanh
$color_white:       #ffffff; // trang
$color_orange:      #f88e4f; // cam

$color_primary: #0A6B4E;
$color_secondary: #BD6D97;
$color_tertiary: #7FBC49;
$color_quaternary: #F6F3F5;
$color_shadow: rgba(0,0,0,.3);
$color_header_icon: #000000;

$white:             #fff;
$black:             #000;
$gray-dark:         #363a42;
$gray-darker:       #232323;
$gray:              #7a7a7a;
$gray-second:       #9b9b9b;
$gray-light:        #f6f6f6;
$gray-light-second: #dbdbdb;
$gray-lighter:      #f6f6f6;
$brand-danger:      $color_primary;
$brand-success:     #00C671;
$brand-primary:     #00C671;

$path_img: '../img/';
$path_fonts: '../fonts/';

$font_primary: 'ubuntu';
$font_secondary: 'ubuntu';

$font_material: 'Material Icons';
$font_awesome: 'FontAwesome';
$font_icon: 'sld-font-icon';

$transition: 300ms;
$shadow: 0 3px 6px $color_shadow;

$header_icon_padding: 7px;
$header_icon_size: rem(20);

// sld font icon
$icomoon-font-family: $font_icon !default;
$icomoon-font-path: "#{$path_fonts}#{$icomoon-font-family}" !default;

$sld_font_icon: $icomoon-font-family;

$sld-icon-animals: "\e915";
$sld-icon-animals-black: "\e925";
$sld-icon-chevron-right: "\e90c";
$sld-icon-check: "\e90f";
$sld-icon-conception: "\e910";
$sld-icon-listen: "\e911";
$sld-icon-email: "\e912";
$sld-icon-less: "\e917";
$sld-icon-gentiana: "\e918";
$sld-icon-more: "\e919";
$sld-icon-sheet: "\e91a";
$sld-icon-formulation: "\e91b";
$sld-icon-search: "\e91c";
$sld-icon-cart: "\e91d";
$sld-icon-cart-black: "\e91e";
$sld-icon-phone: "\e91f";
$sld-icon-testimony-women: "\e920";
$sld-icon-testimony-men: "\e921";
$sld-icon-terrain: "\e922";
$sld-icon-user: "\e923";
$sld-icon-user-black: "\e924";
$sld-icon-cookie: "\e908";
$sld-icon-facebook: "\e903";
$sld-icon-instagram: "\e904";
$sld-icon-linkedin: "\e905";
$sld-icon-pinterest: "\e906";
$sld-icon-rss: "\e907";
$sld-icon-twitter: "\e909";
$sld-icon-vimeo: "\e90a";
$sld-icon-youtube: "\e90b";

//---------- BOOTSTRAP
$grid-columns:      12;
$grid-gutter-width: 20px;
//$grid-gutter-width-small: 20px;
$paragraph-margin-bottom: 0.625rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
  xxl: 1500px,
  xxxl: 1680px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1620px
);

$container-xs-max-width: 480px;

//---------- FONTS
$text-font:                         $font_primary, sans-serif;
$text-color:                        $color_text;

$title-font:                        $font_secondary, sans-serif;
$title-color:                       $color_text_bold;

$border-color:                      $color_border;
$border-color-lighten:              lighten($border-color, 15%);
$dark-border-color:                 #3b3b3b;
$light-border-color:                #ffffff;

$box-shadow-color:                  rgba(0, 0, 0, 0.2);

$link-color:                        $color_primary;
$link-hover-color:                  $color_primary;

$box-background:                    $color_box_bg;
$box-background-darken:             darken($box-background, 8%);
$light-background:                  $color_white;
$box-padding:                       0.9375rem;

$light-text-color:                  $color_white;

$wrapper-background:                #fff;

$center-enable-background:          false;
$center-background:                 #ffffff;
$center-border:                     none;
$center-padding:                    10px;

$column-block-background:           $color_box_bg;

$icon-color:                        $color_secondary;

$header-background:                 #ffffff;
$breadcrumb-background:             #ffffff;

$footer-background:                 #F6F3F5;
$footer-text-color:                 inherit;
$footer-title-color:                $color_text_bold;

//---------- Bootstrap
$font-size-root:    16px;
$font-size-base:    1rem;
$line-height-base:  1.25;

$input-bg:            $color_white;
$input-color:         $text-color;
$input-border-color:  $border-color;
$input-color-placeholder: $color_text_light;
$input-focus-border-color: $border-color;
$input-btn-focus-box-shadow: none;

$btn-border-radius:       11px;
$btn-line-height:         1.25rem;
$input-btn-border-width:  1px;
$input-padding-x:         0.75rem;
$input-padding-y:         0.375rem;
$input-line-height:       $line-height-base;
$input-height:            (($font-size-base * $line-height-base) + ($input-padding-y * 2));

$table-striped-order:   even; // even || odd
$table-color:           $color_text;
$table-bg:              #fff;
$table-accent-bg:       #F2F2F2;
$table-hover-color:     $color_text;
$table-hover-bg:        #c8c8c8;
$table-active-bg:       #f7f7f7;
$table-border-width:    1px;
$table-border-color:    #fff;


$tooltip-color:         #fff;
$tooltip-bg:            #000;
$tooltip-padding-y:     0;
$tooltip-padding-x:     5px;
$tooltip-margin:        2px;
$tooltip-arrow-color:   $tooltip-bg;

$pre-color:               $text-color;
$blockquote-small-color:  $color_text_light;
$thumbnail-border-color:  $border-color;
$input-btn-border-width:  2px;

$primary:       $color_primary;
$secondary:     $color_secondary;

// not bootstrap
$btn-border-width:        1px;
$input-max-width:         220px;
$base-height:             40px;
$base-padding:            parseInt($input-padding-y) * $font-size-root + 1;
$base-padding-border1px:  parseInt($input-padding-y) * $font-size-root;
$base-padding-border2px:  parseInt($input-padding-y) * $font-size-root - 1;

$custom-select-bg:        #fff;
$custom-select-background: none;

$btn-primary-color:         $color_white;
$btn-primary-bg:            $color_primary;
$btn-primary-border:        $color_primary;
$btn-primary-color-hover:   $color_primary;
$btn-primary-bg-hover:      $color_white;
$btn-primary-border-hover:  $color_primary;
$btn-primary-color-focus:   $color_primary;
$btn-primary-bg-focus:      $color_white;
$btn-primary-border-focus:  $color_primary;

$btn-secondary-color:       $color_white;
$btn-secondary-bg:          $color_secondary;
$btn-secondary-border:      $color_secondary;
$btn-secondary-color-hover: $color_secondary;
$btn-secondary-bg-hover:    $color_white;
$btn-secondary-border-hover:$color_secondary;
$btn-secondary-color-focus: $color_secondary;
$btn-secondary-bg-focus:    $color_white;
$btn-secondary-border-focus:$color_secondary;

$btn-cart-color:            $color_white;
$btn-cart-bg:              $color_secondary;
$btn-cart-border:           $color_secondary;
$btn-cart-color-hover:      $color_secondary;
$btn-cart-bg-hover:         transparent;
$btn-cart-border-hover:     $color_secondary;
$btn-cart-color-focus:      $color_white;
$btn-cart-bg-focus:         transparent;
$btn-cart-border-focus:     $color_secondary;

//---------- CLASSIC
$h1-font-size: rem(50);
$h2-font-size: rem(30);
$h3-font-size: rem(24);
$h4-font-size: rem(20);
$h5-font-size: rem(16);
$h6-font-size: rem(14);

$headings-font-weight: 400;
$border-radius: 0;
$extra-small-space: 10px;
$small-space: 20px;
$medium-space: 30px;
$large-space: 40px;
$extra-large-space: 50px;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.1875rem;
$display2-weight: 400;
$display3-size: 1.5625rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
