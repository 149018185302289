@import "../partials/variables";

.block-newsletter {
  padding: 80px 0;
  background: url('#{$path_img}newsletter.jpg');
  background-size: cover;
  background-position: center right;
  color: #fff;
  border-radius: 15px 0 15px 0;
  body#index & { 
    margin-top: -60px;
  }
  @include media-breakpoint-up(xl) {
    min-height: 380px;
  }
  @include media-breakpoint-down(lg) {
    padding: 60px 40px;
  }
  .h4,
  .conditons {
    margin-bottom: 4px;
    @include media-breakpoint-down(lg) {
      text-align: right;
    }
  }
  #gdpr_consent label{
    color: #fff;
  }
  .newsletter-form {
    width: 100%;
    max-width: 775px;
  }
  .js-subscription-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .btn-submit-newsletter {
    color: #fff;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
  .form-control {
    border-color: #fff;
    color: #fff;
    padding-left: 20px;
    font-size: rem(18);
    padding: 13px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: transparent;
    height: initial;
    border-width: 1px;
    border-radius: 12px;
    &.focus,
    &:focus {
      outline: 1px solid #fff;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background: transparent;
      transition: background-color 5000000s ease-in-out 0s;
      -webkit-text-fill-color: #ffffff;
    }
    &::placeholder {
      opacity: 1;
    }
  }
  .input-btn {
    top: 1px;
    right: 1px;
  }
  .btn-light {
    background-color: transparent;
    color: #fff;
    &.hover,
    &:hover {
      background-color: #fff;
      color: $color_text;
    }
  }
  .newsletter-message {
    .alert {
      padding: 2px 10px;
      font-size: inherit;
      margin-bottom: .625rem;
      border: none;
    }
    .conditons {
      padding: 2px 0;
       @include media-breakpoint-down(sm) {
        display: none;
       }
    }
  }
}
#footer .footer-main .block-newsletter h4 {
  margin-bottom: 20px;
}
