.contact-rich {
  hr {
    border-style: dashed;
  }
  .info-line {
    display: flex;
    align-items: center;
    .icon {
      width: 3rem;
      i {
        font-size: 2rem;
      }
    }
    .data {
    }
  }
}

.notice-address-form{
  max-width: 650px;
  margin: 0 auto 20px auto;
}
