@import "../partials/variables";

.footer-top {
  position: relative;
  z-index: 0;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100px;
    background-color: #ffff;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    body#index & {
      content: none;
    }
  }
}