@import "../partials/variables";

// grid products
.elementor-product-grid .product-miniature {
  height: 100%;
  padding: 0 10px;
}
.elementor-widget-product-carousel {
  .slick-track {
    display: flex;
    align-items: stretch;
    padding-bottom: 10px;
  }
  .slick-slide-inner,
  .product-miniature,
  .product-container {
    height: 100%;
  }
  .slick-slide {
    height: initial;
    > div {
      height: 100%;
      > .slick-slide {
        height: 100%;
      }
    }
  }
  .slick-slide-inner {
    padding: 0 10px;
  }
}
.elementor-widget-product-grid {
  .product-container {
    height: 100%;
  }
  .product-miniature {
    margin-bottom: 20px;
  }
}
.grid .product-container {
  min-height: 100%;
}
.elementor-product-grid,
.elementor-widget-product-carousel,
.grid {
  //product container
  .product-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .second-third-block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .second-block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .third-block {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 36px;
    padding: 8px 5px;
  }
  // prices
  .product-price {
    display: flex;
  }
  .first-prices {
    font-weight: 700;
    font-size: rem(20);
    .product-price {
      font-size: inherit;
    }
  }
  .second-prices {
    text-decoration: line-through;
    font-weight: 700;
    color: rgba(#000, .43);
  }
  .regular-price {
    font-size: rem(16);
    margin-left: 6px;
    margin-right: 6px;
    color: rgba(#000, .43);
  }
  .text-for-list {
    display: none;
  }
}
// END - grid products

// product list manufacturer image or name
.product-list {
  .product-manufacturer {
    img {
      display: block;
      max-width: 120px;
      max-height: 40px;
    }
  }
  .product-manufacturer {
    display: flex;
    justify-content: center;
  }
  .bestkit_icons_title {
    display: none;
  }
}
// END - product list manufacturer
.product-list {
  .slick-next,
  .slick-prev {
    &:before {
      display: inline-block;
      content: '\e90d' !important;
      font-size: 12px !important;
      line-height: 30px !important;
      font-family: $font_icon !important;
      color: #000 !important;
    }
    width: 30px !important;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #b8b8b8;
    box-shadow: $shadow;
    opacity: 1;
    &.slick-disabled {
      opacity: .5 !important;
    }
  }
  .slick-prev {
    &:before {
      transform: rotate(180deg);
    }
  }
}
// END - product list manufacturer