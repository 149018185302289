@import "../partials/variables";

// page pack
.ap5-buy-block {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #F3F3F3;
  box-shadow: 0 3px 6px #F3F3F3;
  min-height: 95px;
}
.ap5-add-to-cart-container {
  margin-left: auto;
  .product-add-to-cart {
    height: 100%;
  }
  .product-quantity {
    display: flex !important;
    height: 100%;
  }
}
.main-product-bottom {
  padding-top: 60px;
  @include media-breakpoint-down(sm) {
    padding-top: 30px;
  }
  #ap5-product-list {
    position: relative;
    z-index: 0;
    background-color: #f4f4f4;
    padding-top: 30px;
    padding-bottom: 30px;
    &:before,
    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 25vw;
      background-color: #f4f4f4;
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }
  .ap5-pack-product:not(:last-child) {
    position: relative;
    @include media-breakpoint-down(sm) {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to right, $color_primary, $color_secondary);
      }
    }
  }
  .ap5-pack-product-content {
    display:  flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }
  .ap5-pack-images-container {
    width: 100%;
  }
  .ap5-pack-product-text {
    display: flex;
    @include media-breakpoint-up(md) {
      padding-left: 30px;
    }
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }
  }
  .ap5-pack-product-name {
    min-width: 250px;
    font-size: rem(14);
    font-weight: 700;
    color: $color_text;
    letter-spacing: 0.02em;
    padding: 8px 10px;
    a {
      color: inherit;
      &:hover {
        color: $color_secondary;
      }
    }
    @include media-breakpoint-down(lg) {
      width: 70%;
    }
  }
  .ap5-pack-product-price-table-container {
    flex: 1;
    min-width: 150px;
    padding: 4px 10px;
    @include media-breakpoint-down(lg) {
      width: 30%;
    }
  }
  .product-variants-item {
    padding: 4px 10px;
    margin-bottom: 8px;
    background-color: #fff;
    .control-label {
      font-weight: 700;
      margin-bottom: 4px;
    }
    .input-container {
      position: relative;
      &:hover {
        .input-radio:not(:checked) {
          &+ .radio-label {
            background-color: rgba($color_secondary, .3);
          }
        }
      }
    }
    .input-radio {
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      &:checked {
        &+ .radio-label {
          border-color: $color_secondary;
        }
      }
    }
    .radio-label {
      display: inline-block;
      border: 2px solid transparent;
      border-radius: 3px;
      padding: 3px 6px;
    }
  }
}

// page product footer
#ap5-page-product-box {
  .ap5-pack-product {
    position: relative;
    padding: 10px;
    &:not(:last-child) {
      &:before {
        content: '\f055';
        font-family: $font_awesome;
        font-size: 2rem;
        position: absolute;
        z-index: 10;
        top: calc(50% - 1rem);
        right: -14px;
      }
    }
  }
  .ap5-product-footer-pack-informations {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ap5-view-pack-name {
    font-size: 1.4rem;
    color:$color_tertiary;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .btn-secondary {
    padding: 10px 20px;
  }
  .ap5-pack-product-content {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }
}
