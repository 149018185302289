@import "../partials/variables";

.customization-modal {
  .modal-body {
    padding: 0;
  }
  .product-customization-line {
    padding: 15px 0;
    display: flex;
    + .product-customization-line {
      border-top: 1px solid $border-color-lighten;
    }
  }
}