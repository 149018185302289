@import "../partials/variables";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:
            url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8wdqe9') format('truetype'),
            url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8wdqe9') format('woff'),
            url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8wdqe9##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="sld-icon-"], [class*=" sld-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sld-icon-animals {
    &:before {
      content: $sld-icon-animals; 
    }
  }
  .sld-icon-animals-black {
    &:before {
      content: $sld-icon-animals-black; 
    }
  }
  .sld-icon-chevron-right {
    &:before {
      content: $sld-icon-chevron-right; 
    }
  }
  .sld-icon-check {
    &:before {
      content: $sld-icon-check; 
    }
  }
  .sld-icon-conception {
    &:before {
      content: $sld-icon-conception; 
    }
  }
  .sld-icon-listen {
    &:before {
      content: $sld-icon-listen; 
    }
  }
  .sld-icon-email {
    &:before {
      content: $sld-icon-email; 
    }
  }
  .sld-icon-less {
    &:before {
      content: $sld-icon-less; 
    }
  }
  .sld-icon-gentiana {
    &:before {
      content: $sld-icon-gentiana; 
    }
  }
  .sld-icon-more {
    &:before {
      content: $sld-icon-more; 
    }
  }
  .sld-icon-sheet {
    &:before {
      content: $sld-icon-sheet; 
    }
  }
  .sld-icon-formulation {
    &:before {
      content: $sld-icon-formulation; 
    }
  }
  .sld-icon-search {
    &:before {
      content: $sld-icon-search; 
    }
  }
  .sld-icon-cart {
    &:before {
      content: $sld-icon-cart; 
    }
  }
  .sld-icon-cart-black {
    &:before {
      content: $sld-icon-cart-black; 
    }
  }
  .sld-icon-phone {
    &:before {
      content: $sld-icon-phone; 
    }
  }
  .sld-icon-testimony-women {
    &:before {
      content: $sld-icon-testimony-women; 
    }
  }
  .sld-icon-testimony-men {
    &:before {
      content: $sld-icon-testimony-men; 
    }
  }
  .sld-icon-terrain {
    &:before {
      content: $sld-icon-terrain; 
    }
  }
  .sld-icon-user {
    &:before {
      content: $sld-icon-user; 
    }
  }
  .sld-icon-user-black {
    &:before {
      content: $sld-icon-user-black; 
    }
  }
  .sld-icon-cookie {
    &:before {
      content: $sld-icon-cookie; 
    }
  }
  .sld-icon-facebook {
    &:before {
      content: $sld-icon-facebook; 
    }
  }
  .sld-icon-instagram {
    &:before {
      content: $sld-icon-instagram; 
    }
  }
  .sld-icon-linkedin {
    &:before {
      content: $sld-icon-linkedin; 
    }
  }
  .sld-icon-pinterest {
    &:before {
      content: $sld-icon-pinterest; 
    }
  }
  .sld-icon-rss {
    &:before {
      content: $sld-icon-rss; 
    }
  }
  .sld-icon-twitter {
    &:before {
      content: $sld-icon-twitter; 
    }
  }
  .sld-icon-vimeo {
    &:before {
      content: $sld-icon-vimeo; 
    }
  }
  .sld-icon-youtube {
    &:before {
      content: $sld-icon-youtube; 
    }
  }
  
  