@import "../partials/variables";

$block_direction_desktop: column; // row || column || row-reverse || column-reverse
$block_direction_tablet: column; // row || column || row-reverse || column-reverse
$block_direction-mobile: column; // row || column || row-reverse || column-reverse
$block_align_desktop: flex-start; // flex-start || flex-end || center
$block_align_tablet: flex-start; // flex-start || flex-end || center
$block_align_mobile: flex-start; // flex-start || flex-end || center
$block_justify_desktop: flex-start; // flex-start || flex-end || center
$block_justify_tablet: flex-start; // flex-start || flex-end || center
$block_justify_mobile: flex-start; // flex-start || flex-end || center
$block_column_desktop: 3; // nb column 12
$block_column_tablet: 6; // nb column 12
$block_column_mobile: 12; // nb column 12

// reassurance product
.block-reassurance-product {
  display: flex;
  flex-wrap: wrap;
  .block-reassurance-item {
    @include media-breakpoint-up(xl) {
      width: 50%;
    }
  }
  .block-reassurance-icon {
    min-width: 60px;
    padding-right: 10px;
    margin-bottom: 10px;
    img,
    svg {
      height: 40px;
      width: auto;
    }
  }
  .block-reassurance-title {
    font-size: 1rem;
  }
}
// reassurance homepage
// reassurance default
.block-reassurance-home,
.block-reassurance-default,
.block-reassurance-outside {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
  .block-reassurance-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      align-items: $block_align_mobile;
      justify-content: $block_justify_mobile;
      flex-direction: $block_direction_mobile;
      @include make-col($block_column_mobile);
    }
    @include media-breakpoint-between(md, lg) {
      align-items: $block_align_tablet;
      justify-content: $block_justify_tablet;
      flex-direction: $block_direction_tablet;
      @include make-col($block_column_tablet);
    }
    @include media-breakpoint-up(xl) {
      align-items: $block_align_desktop;
      justify-content: $block_justify_desktop;
      flex-direction: $block_direction_desktop;
      @include make-col($block_column_desktop);
    }
  }
  .block-reassurance-icon {
    margin-right: 10px;
  }
  .block-reassurance-title {
    @include media-breakpoint-down(xs) {
      min-width: 100%;
    }
    @include media-breakpoint-up(sm) {
      min-width: 120px;
    }
  }
  .block-reassurance-description {
    max-width: 90%;
  }
}
// reassurance globals
.block-reassurance-icon {
  @include media-breakpoint-down(sm) {
    padding: 0 0 10px;
  }
  @include media-breakpoint-between(md, lg) {
    padding: 0 0 10px;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 0 10px;
  }
  img,
  svg {
    height: 50px;
    width: auto;
    fill: $color_secondary;
  }
}
.block-reassurance-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.block-reassurance-title {
  font-size: rem(16);
  font-weight: 600;
  color: $color_text;
  margin-bottom: 6px;
}
.block-reassurance-description {
  font-size: rem(14);
  color: $color_text_light;
}
