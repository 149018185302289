@import '../partials/variables';

.user-info {
  .account-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $header_icon_padding;
    color: #fff;
    .sld-icon-user-black {
      display: none;
    }
    &.focus,
    &:focus,
    &.hover,
    &:hover {
      color: #fff;
      .sld-icon-user {
        display: none;
      }
      .sld-icon-user-black {
        display: block;
      }
    }
    .customer-title {
      font-size: rem(18);
      font-weight: 700;
      margin-left: 6px;
    }
    .sld-icon-user-black,
    .sld-icon-user {
      font-size: $header_icon_size;
      line-height: $header_icon_size;
    }
  }
  .dropdown-customer-account-links {
    position: absolute;
    top: 110%;
    right: 0;
    min-width: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    .dropdown-menu {
      display: block;
      width: 100%;
      position: initial;
      box-shadow: $shadow;
      border-radius: $border-radius;
      padding: 0;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      i, span {
        margin: 0 5px;
      }
      i {
        font-size: 15px;
      }
    }
    .logout {
      border-top: 1px solid $border-color;
      padding: 0;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        transition: $transition background-color, $transition color;
        &:hover {
          background-color: #ff3939;
          color: $color_white;
        }
      }
      i {
        min-width: 18px;
        margin-left: 4px;
      }
    }
    .logout-link {
      display: block;
      padding: $base-padding;
      text-align: center;
      color: #ff3939;
    }
  }
  .customer-logged {
    position: relative;
    &:hover, .hover {
      .dropdown-customer-account-links {
        visibility: visible;
        opacity: 1;
        top: calc(100% - 2px);
        transition: all 0.3s;
      }
    }
  }
}
// mobile
.mobile-header-version {
  .dropdown-customer-account-links,
  .customer-title {
    display: none;
  }
}