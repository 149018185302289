.slick-list {
  padding-top: 15px;
  margin-top: -15px;
}
div.slick-track {
  display: flex;
}
div.slick-slide {
  height: auto;
  outline: none;
}

.slick-loading .slick-list {
  background: transparent url('#{$path_img}loading.gif') center center no-repeat;
}

.slick-prev,
.slick-next {
  font-size: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 30px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s;
  opacity: 0.5;
  z-index: 10;
  &:hover {
    outline: none;
    opacity: 1 !important;
  }
  &:focus {
    outline: none;
  }
  &.slick-disabled {
    opacity: .25;
  }
  .material-icons {
    transition: all 0.3s;
    display: initial;
    
  }
  &:before {
    font-family: $font_icon;
    content: '\e904';
    font-size: rem(50);
    transition: all 0.3s;
  }

  .background-for-title & {
    top: -40px;
  }
  @include media-breakpoint-down(md) {
    width: 25px;
    height: 25px;
    &:before {
      line-height: 25px;
      font-size: rem(30);
    }
  }
}

.slick-prev {
  left: -60px;
  &:before {
    content: "\e904";
  }
  @include media-breakpoint-down(xl) {
    left: -40px;
  }
  @include media-breakpoint-down(md) {
    left: -10px;
  }
}

[dir='rtl'] .slick-prev {
  right: -8px;
  left: auto; }

.slick-next {
  right: -60px;
  border-radius: 0 50% 50% 0;
  &:before {
    content: "\e90c";
  }
  @include media-breakpoint-down(xl) {
    right: -40px;
  }
  @include media-breakpoint-down(md) {
    right: -10px;
  }
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -8px; }

/* Dots */
.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center !important;
  margin: 0;
  li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
    padding: 5px 2px;
    cursor: pointer;
    button {
      font-size: 0;
      display: block;
      width: 13px;
      height: 13px;
      padding: 0;
      cursor: pointer;
      border: 0;
      outline: none;
      background: $icon-color;
      border-radius: 50%;
      opacity: .25;
      &:hover, &:focus {
        outline: none;
      }
      &:hover {
        opacity: 1;
      }
    }
    &.slick-active button {
      opacity: 1; 
    }
  }
}
