@import "../partials/variables";

.aone-slideshow {
  .nivo-caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    overflow: hidden;
    background: transparent;
    opacity: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    .caption-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .caption-content {
      padding: 10px 5px;
      position: relative;
      text-align: center;
      color: $light-text-color;
      font-size: 18px;
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 5%;
        margin-top: 0;
        font-size: 60px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        line-height: normal;
        color: inherit;
        text-shadow: 0 1px 10px rgba(0, 0, 0, 0.6);
      }
      .text {
        margin-bottom: 8%;
        text-shadow: 0 1px 10px rgba(0, 0, 0, 0.6);
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      p {
      }
      .btn {
        padding-top: 0.56em;
        padding-bottom: 0.56em;
        line-height: normal;
        font-size: 20px;
      }
      @include media-breakpoint-down(md) {
        font-size: 15px;
        h1, h2, h3, h4, h5, h6 {
          font-size: 35px;
          letter-spacing: 0.1em;
        }
        .btn {
          font-size: 16px;
        }
      }
      @include media-breakpoint-down(sm) {
        font-size: 12px;
        h1, h2, h3, h4, h5, h6 {
          font-size: 25px;
          letter-spacing: 0.1em;
        }
        .btn {
          font-size: 12px;
        }
      }
    }
    .slide-link {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border: none;
    }
  }
  .nivo-html-caption {
    display:none;
  }

  .nivoSlider {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    img {
      position: absolute;
      top: 0px;
      left: 0px;
      max-width: none;
      width: 100%;
      height: auto;
    }
    .nivo-caption {
      display: none;
    }
    .nivo-main-image {
      position: relative !important; 
      width: 100% !important;
      height: auto !important;
    }
    a.nivo-imageLink {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 6;
      display: none;
      background: white; 
      filter: alpha(opacity=0); 
      opacity: 0;
    }
    .nivo-slice {
      display: block;
      position: absolute;
      z-index: 5;
      height: 100%;
      top: 0;
    }
    .nivo-box {
      display: block;
      position: absolute;
      z-index: 5;
      overflow: hidden;
      img {
        display: block;
      }
    }
    .nivo-directionNav {
      position: static;
      opacity: 1;
      visibility: visible;
      .nivo-prevNav, .nivo-nextNav {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        z-index: 9;
        cursor: pointer;
        font-size: 40px;
        display: flex;
        background-color: $light-background;
        border-radius: 50%;
        border: 0;
        opacity: 0;
        user-select: none;
        color: $icon-color;
        transition: all 0.3s;
        &:hover {
          opacity: 1!important;
        }
        .fa {
          margin-top: 0;
          text-align: center;
        }
        .category-images-slider & {
          font-size: 33px;
        }
      }
      .nivo-prevNav {
        left: 2%;
      }
      .nivo-nextNav {
        right: 2%;
      }
    }
    .nivo-controlNav {
      text-align: center;
      padding: 0 0 1%;
      position: absolute;
      bottom: 0;
      left: 10%;
      z-index: 10;
      a {
        cursor: pointer;
        font-size: 0;
        display: inline-block;
        margin: 0 7px;
        width: 12px;
        height: 12px;
        vertical-align: middle;
        background: $icon-color;
        border-radius: 100%;
        border: 2px solid transparent;  
        &.active {
          height: 14px;
          width: 14px;
          background: transparent;
          border-color: $icon-color;
        }
      }
    }
  }
}

.aone-slideshow.theme-default {
  .nivoSlider {
    display: none;
  }
  .nivo-slider-overlay {
    background: transparent url('#{$path_img}loading.gif') no-repeat 50% 50%;
  }
  &:hover .nivo-directionNav a { 
    opacity: 0.5;
  }
  .caption-effect-opacity .nivo-caption {
    opacity: 0;
  }
  .caption-effect-left .nivo-caption {
    left: -100%;
  }
  .caption-effect-right .nivo-caption {
    right: -100%;
  }
  .caption-effect-top .nivo-caption {
    top: -100%;
  }
  .caption-effect-bottom .nivo-caption {
    bottom: -100%;
  }

  .nivo-controlNav.nivo-thumbs-enabled {
    width: 100%;
    left: 0;
    a {
      width: auto;
      height: auto;
      &:before {
        font-size: 0;
        display: none;
      }
    }
    img {
      display: block;
      height: auto;
    }
  }
  .nivo-directionNav, .nivo-controlNav {
    &.disabled a {
      pointer-events: none;
      cursor: default;
    }
  }
}

.aone-slideshow {
  @include media-breakpoint-down(sm) {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  .a-banner {
    position: relative;
    img {
      width: 100%;
    }
  }
  .slide-products-related {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(0, -50%);
    width: 130px;
    border-radius: 6px;
    overflow: hidden;
    .slide-products-related-list {
      background: $light-background;
      opacity: 0.8;
      transition: all 0.3s;
      &:hover {
        opacity: 1;
      }
      height: 100%;
    }
    .product-item {
      .product-container {
        overflow: hidden;
        position: relative;
        padding-bottom: 8px;
      }
      .product-name {
        padding: 5px 3px;
        text-align: center;
        font-size: 13px;
      }
      .product-thumbnail img {
        display: block;
        position: initial;
        width: 100%;
      }
      .regular-price {
        display: none;
      }
    }
  }
}
