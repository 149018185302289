$bgColorOverlay: rgba(0,0,0,0.5);
$sizeDot: 30px;

.sld-loader-overlay {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bgColorOverlay;
}

.sld-loader-content {
    max-width: 250px;
    min-width: $sizeDot * 4;
    min-height: $sizeDot;
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sld-loader-dot {
    position: absolute;
    z-index: 10;
    top: 0;
    display: block;
    width: $sizeDot;
    height: $sizeDot;
    border-radius: 50%;

    &.dot-0 {
        animation: dotsFadeIn 1s infinite;
        left: $sizeDot * 3;
    }
    &.dot-1 {
        animation: dotsMove 1s infinite;
        left: $sizeDot * 3;
    }
    &.dot-2 {animation: dotsFadeOut 1s infinite;
        left: 0;

    }
}

@keyframes dotsFadeIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes dotsMove {
    0% {
      transform: translate(0);
    }
    100% {
      transform: translate(-($sizeDot * 1.5));
    }
  }
  
  @keyframes dotsFadeOut {
    0% {
      opacity: 1;
      transform: translate($sizeDot * 1.5);
    }
    50% {
      transform: translate(0);
    }
    100% {
      opacity: 0;
    }
  }