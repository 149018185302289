@import "../partials/variables";

$menu-color:                $color_text;
$menu-title-color:          $color_text_bold;
$menu-background:           #f7f7f7;
$menu-dropdown-full:        true;
$container-width-sm:        map-get($container-max-widths, sm);
$container-width-md:        map-get($container-max-widths, md);
$container-width-lg:        map-get($container-max-widths, lg);
$container-width-xl:        map-get($container-max-widths, xl);
$container-width-xxl:       map-get($container-max-widths, xxl);
$container-width-xxxl:       map-get($container-max-widths, xxxl);

.header-display-navfullwidth #amegamenu {
  margin-right: 10px;
  .adropdown {
    position: absolute;
    left: 0;
    top: 110%;
    max-width: calc(100vw - 18px);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 2px 4px $box-shadow-color;
    background: transparent;
    border-radius: 0 0 5px 5px;
    overflow: auto;
    max-height: calc(100vh - 210px);
    @if $menu-dropdown-full == false {
      @include media-breakpoint-only(sm) {
        max-width: $container-width-sm - $grid-gutter-width;
      }
      @include media-breakpoint-only(md) {
        max-width: $container-width-md - $grid-gutter-width;
      }
      @include media-breakpoint-only(lg) {
        max-width: $container-width-lg - $grid-gutter-width;
      }
      @include media-breakpoint-up(xl) {
        max-width: $container-width-xl - $grid-gutter-width;
      }
      @include media-breakpoint-up(xxl) {
        max-width: $container-width-xxl - $grid-gutter-width;
      }
    }
    .dropdown-bgimage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
    }
    $d1w: ($container-width-xxxl - $grid-gutter-width) / 5;
    &.adrd1 {
      min-width: 300px;
      .dropdown-content,
      .sub-dropdown-content { max-width: $d1w; }
    }
    &.adrd2 {
      min-width: 600px;
      .dropdown-content,
      .sub-dropdown-content {
        // max-width: $d1w * 2;
        &.acot1,
        &.sub-dropdown-content { width: percentage(1/2); }
      }
    }
    &.adrd3 {
      min-width: 900px;
      .dropdown-content,
      .sub-dropdown-content {
        // max-width: $d1w * 3;
        &.acot1 { width: percentage(1/3); }
        &.acot2 { width: percentage(2/3); }
      }
    }
    &.adrd4 {
      min-width: 1200px;
      .dropdown-content,
      .sub-dropdown-content {
        // max-width: $d1w * 4;
        &.acot1 { width:percentage(1/4); }
        &.acot2 { width: percentage(2/4); }
        &.acot3 { width: percentage(3/4); }
      }
    }
    &.adrd5 {
      min-width: 1500;
      .dropdown-content,
      .sub-dropdown-content {
        // max-width: $d1w * 5;
        &.acot1 { width: percentage(1/5); }
        &.acot2 { width: percentage(2/5); }
        &.acot3 { width: percentage(3/5); }
        &.acot4 { width: percentage(4/5); }
      }
    }
  }
}
.amegamenu_rtl .anav-top .adropdown {
  left: auto;
  right: 0;
}
