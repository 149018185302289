@import "../partials/variables";

$header_padding_desktop: 30px;
$header_padding_mobile: 10px;
$header_height_desktop: 70px;
$header_height_mobile: 60px;
$menu_burger_width: 20px;
$menu_burger_color: #fff;
$menu_burger_color_close: #fff;
$menu_burger_line_height: 2px;

#header {
  .left-nav-trigger {
    .left-nav-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
      color: $color_primary;
      font-size: 30px;
      cursor: pointer;
      user-select: none;
      transition: $transition color;
      margin-right: 10px;
      &:hover {
        color: $link-hover-color;
      }
    }
  }
  .sld-menu-navbar-back {
    .left-nav-icon {
      width: auto;
    }
  }
  p {
    margin-bottom: 0;
  }
}
.sld-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0,0,0,.3);
}
.desktop-header-version {
  position: relative;
  z-index: 2000;
  padding-right: 40px;
  padding-left: 40px;
  .header-event-banner {
    padding-left: $header_padding_desktop;
    padding-right: $header_padding_desktop;
    background-color: $header-background;
  }
  .header-display-nav1 {
    padding-left: $header_padding_desktop;
  }
  .header-display-nav2 {
    padding-right: $header_padding_desktop;
    ul {
      display: flex;
      align-items: center;
      li {
        padding-left: 20px;
      }
      a {
        font-weight:  700;
        color: #5A5A5A;
        &.focus,
        &:focus,
        &.hover,
        &:hover {
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }
  }
  .header-display-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .language-selector-wrapper {
    .dropdown-menu {
      z-index: 4000;
    }
  }
  .dropup .dropdown-toggle:after {
    transform: rotate(180deg);
    transform-origin: center 80%;
  }
  .header-main {
    z-index: 1000;
    background-color: $color_primary;
    color: #fff;
    padding-left: $header_padding_desktop;
    padding-right: $header_padding_desktop;
    min-height: $header_height_desktop;
    border-radius: 11px 0 11px 0;
    &.sticky-active {
      box-shadow: 0 0 25px #0000004D;
    }
  }
  .header-bottom {
    padding-left: $header_padding_desktop;
    padding-right: $header_padding_desktop;
  }
  @include media-breakpoint-down($menu_breakpoint_down) {
    .header-display-navfullwidth {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
    .searchbar-wrapper {
      margin-left: auto;
    }
  }
}
.desktop-sticky-wrapper {
  &.is-sticky {
    @include media-breakpoint-up($menu_breakpoint_up) {
      #amegamenu .anav-top {
        min-height: 50px;
      }
    }
  }
}
// header banner
.header-event-banner {
  display: flex;
  align-items: center;
  font-size: rem(14);
  min-height: 30px;
}
// END - header banner

// header top
.header-top {
  display: flex;
  align-items: center;
  background-color: $header-background;
}
// END - header top

// header main
.header-display-left {
  position: relative;
  display: flex;
  align-items: center;
}
.left-nav-trigger.active {
  .sld-menu-burger {
    &:before,
    &:after,
    span {
      background-color: $menu_burger_color_close;
      
    }
  }
}
.sld-menu-burger {
  width: $menu_burger_width;
  height: $menu_burger_width;
  position: relative;
  
  .left-nav-trigger.active & {
    &:before {
      left: 20%;
      transform: rotate(45deg);
    }
    span {
      left: 0;
      width: 100%;
      transform: rotate(-45deg);
    }
    &:after {
      opacity: 0;
    }
  }
  &:before,
  &:after {
    position: absolute;
    z-index: 10;
    content: '';
    display: block;
    height: $menu_burger_line_height;
    border-radius: $menu_burger_line_height;
    background-color: $menu_burger_color;
    transition: $transition all;
  }
  &:before {
    top: calc(20% - 1px);
    left: 0;
    transform-origin: 0 (-$menu_burger_line_height);
    width: 100%;
  }
  &:after {
    top: calc(80% - 1px);
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
  }
  span {
    position: absolute;
    z-index: 10;
    top: calc(50% - 1px);
    left: 50%;
    width: 70%;
    transform: translateX(-50%);
    height: $menu_burger_line_height;
    border-radius: $menu_burger_line_height;
    background-color: $menu_burger_color;
    transition: $transition all;
  }
}
.header-main {
  position: relative;
  z-index: 2000;
  display: flex;
  justify-content: space-between;
}
.header-main-content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.header-display-nav1,
.header-display-nav2 {
  display: flex;
  align-items: center;
}
.header-display-nav2 {
  flex: 1;
}
.header-display-nav1 {
  justify-content: flex-start;
}
.header-display-nav2 {
  justify-content: flex-end;
}
.header-logo {
  z-index: 20;
  flex: none;
  max-width: 100%;
  svg, img {
    max-width: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
  }
  .svg-logo {
    min-width: 120px;
  }
}
.header-display-top {
  display: flex;
  align-items: center;
  flex: 1;
}
.header-display-right-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
// END -header main

// header bottom
.header-bottom {
}
// END - header bottom

// sticky
.sticky-background {
  display: none;
}
.mobile-sticky-wrapper {
  background: inherit;
  &.is-sticky {
    .sticky-background {
      display: block;
      position: absolute;
      left: -100%;
      right: -100%;
      bottom: 0;
      height: 55px;
      background: inherit;
      box-shadow: 0 1px 5px $box-shadow-color;
      z-index: -1;
      .boxed-layout & {
        left: -15px;
        right: -15px;
      }
    }
  }
}

// mobile
.mobile-header-version {
  background: inherit;
  .header-event-banner {
    position: relative;
    z-index: 2000;
    padding-left: $header_padding_mobile;
    padding-right: $header_padding_mobile;
    background-color: $header-background;
    justify-content: center;
  }
  .header-main-content {
    flex-wrap: nowrap;
  }
  .header-main {
    position: relative;
    z-index: 2000;
    background-color: #0A7152;
    padding-left: $header_padding_mobile;
    padding-right: $header_padding_mobile;
    min-height: $header_height_mobile;
  }
  .header-display-nav1 {
    padding-left: $header_padding_mobile;
  }
  .header-display-nav2 {
    padding-right: $header_padding_mobile;
  }
  .header-nav {
    .header-nav-wrapper {
      min-height: 35px;
    }
  }
  .main-header {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .header-logo {
    max-height: 50px;
    width: auto;
    margin-top: 5px;
    img {
      width: auto;
      height: 50px;
    }
  }
  .header-display-right {
    margin: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 50px;
    display: flex;
    align-items: center;
  }
  .header-display-top {
    justify-content: center;
  }
  .display-top {
    flex: 1;
  }
  .header-bottom {
    padding-left: $header_padding_mobile;
    padding-right: $header_padding_mobile;
  }
  .mobile-menu-icon {
    height: $base-height;
    width: $base-height;
    font-size: 24px;
    border-radius: 3px;
    transition: all 0.3s;
    cursor: pointer;
    user-select: none;
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }
  .mobile-menu-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}
// END - mobile

// st menu left
.st-menu-open.st-effect-left {
  .header-main {
    #search_widget {
      opacity: 0;
      visibility: hidden;
    }
  }
}
.sld-menu-navbar-back {
  position: absolute;
  z-index: 3000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $header-background;
  opacity: 0;
  visibility: hidden;
  transition: $transition opacity $transition visibility;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
// END - st menu left

.top-column-content {
  padding: 20px 40px 0;
}