@import "../partials/variables";

.dropdown-customer-login  {
  .dropdown-menu {
    float: none;
  }
  .customer-login-title {
    padding: 20px 20px 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    color: $color_primary;
    border-bottom: 1px solid #F3F2F2;
  }
  #submit-login-header {
    border-radius: 40px;
    font-weight: 700;
  }
  .form-control {
    height: 40px;
  }
}
.sld-show-customer-login {
  #wrapper,
  #footer {
    &:before{
      content: '';
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
    }
  }
  .forgot-password {
    text-align: center;
    padding: 0 20px 20px;
    a {
      display: inline-block;
      font-size: 0.9rem;
      font-weight: 700;
      max-width: 200px;
      color: $color_primary;
      @include underlineHover($color_primary, 'start', -2px, 1px);
    }
  }
  .create-account {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 10px 20px 20px;
    .btn {
      border-radius: 40px;
      font-weight: 700;
      white-space: nowrap;
    }
  }
  .form-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-footer-text {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #5A5A5A;
    text-align: center;
  }
}
.sld-customer-overlay {
  position: fixed;
  z-index: 110;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dropdown-customer-account-links {
  top: 110%;
}
.dropdown-customer-login {
  top: 100%;
  .form-group {
    margin-bottom: 0;
  }
  .js-parent-focus {
    .btn {
      padding: 6px 10px;
    }
  }
}
.customer-signin-module {
  .dropdown-customer-account-links,
  .dropdown-customer-login {
    position: absolute;
    right: 0;
    min-width: 300px;
    z-index: 3000;
    padding-top: 10px;
    visibility: hidden;
    opacity: 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 40px;
      width: 0;
      height: 0;
      @include triangle(10px, 'top', #fff);
    }
  }
  .dropdown-menu {
    display: block;
    width: 100%;
    position: initial;
    border: none;
    border-radius: 0;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 3px;
    color: #000;
  }
  .dropdown-item i {
    margin: 0 5px;
    font-size: 15px;
  }
  .logout {
    text-align: center;
    color: $color_secondary;
    border-top: 1px solid $color_secondary;
  }
  .logout i {
    min-width: 18px;
  }
  .form-group {
    padding: 0 20px;
  }
  .sld-error-display {
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
  }
}
.customer-info:hover .dropdown-customer-account-links {
  visibility: visible;
  opacity: 1;
  top: 100%;
  transition: all 0.3s;
}
.user-info.hover {
  position: relative;
  .dropdown-customer-account-links,
  .dropdown-customer-login {
    visibility: visible;
    opacity: 1;
    top: 100%;
    transition: all 0.3s;
    padding-top: 0;
  }
}