@import "../partials/variables";

.elementor {
  // buttons
  .elementor-element {
    &.elementor-button-primary {
      .elementor-button {
        border-width: 1px;
        border-style: solid;
        @include my-button-variant(
          $btn-primary-color,
          $btn-primary-bg,
          $btn-primary-border,
          $btn-primary-color-hover,
          $btn-primary-bg-hover,
          $btn-primary-border-hover,
          $btn-primary-color-focus,
          $btn-primary-bg-focus,
          $btn-primary-border-focus
        );
      }
    }
    &.elementor-button-secondary {
      .elementor-button {
        @include my-button-variant(
          $btn-secondary-color,
          $btn-secondary-bg,
          $btn-secondary-border,
          $btn-secondary-color-hover,
          $btn-secondary-bg-hover,
          $btn-secondary-border-hover,
          $btn-secondary-color-focus,
          $btn-secondary-bg-focus,
          $btn-secondary-border-focus
        );
      }
    }
    .elementor-button-link-arrow {
      .elementor-button {
        background-color: transparent;
        padding: 4px 0;
        .elementor-button-text {
          text-decoration: underline;
          text-underline-offset: 4px;
          text-decoration-thickness: 2px
        }
        .elementor-button-icon {
          font-size: 0.65em;
        }
      }
    }
  }
  a.elementor-button:not(#e), 
  a.elementor-button:not(#e):hover, 
  a.elementor-button:not(#e):focus, 
  a.elementor-button:not(#e):visited {
    color: inherit;
  }
  // END - buttons

  // grid products
  .elementor-product-grid {
    grid-column-gap: 0;
  }
  // grid products

  .elementor-slick-slider {
    .slick-prev,
    .slick-next {
      top: 50%;
      transform: translateY(-50%);
      &::before {
        font-family: $font_icon;
      }
    }
    .slick-prev {
      left: -80px;
      &:before {
        content: '\e904';
      }
    }
    .slick-next {
      right: -80px;
      &:before {
        content: '\e90c';
      }
    }
  }
}

.sld-mea-categories-slider {
  .elementor-cta-bg-wrapper {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(1deg, rgba(0,0,0,.7) 10%, transparent);
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }
  }
  .elementor-cta-content {
    position: relative;
    .elementor-cta-title {
      width: calc(100% - 50px);
    }
  }
}

.sld-mea-home-categorie {
  transition: $transition transform;
  body:not(.touch-screen) & {
    &:hover {
      transform: translateY(-30px);
      div.elementor-cta-button-wrapper {
        margin-bottom: 0;
      }
    }
    div.elementor-cta-button-wrapper {
      margin-bottom: -60px;
      transition: $transition margin-bottom;
    }
  }
  .elementor-button {
    width: 100%;
  }
}

.elementor {
  .elementor-section.elementor-section-boxed>.elementor-container {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @if $breakpoint != xs {
        @media all and (min-width: map-get($grid-breakpoints, $breakpoint)) {
          max-width:  map-get($container-max-widths, $breakpoint);
        }
      }
    }
  }
  .swiper-slide{
    height: auto;
  }
}

.cms-id-6 h1.page-heading{
  font-size: 50px;
  text-transform: none;
  line-height:initial;
  padding: 10px;
  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
}
