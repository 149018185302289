@import "../partials/variables";

.#{$fa-css-prefix} {
  min-width: 1em;
  height: 1em;
  line-height: 1;
}

body {
  font-family: $text-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  line-height: $line-height-base + rem;
}
ul {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
a {
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }
  .material-icons, .fa {
    margin-top: -2px;
  }
}
li a, .li-a {
  color: inherit;
}
p {
  margin-bottom: 0.625rem;
}
h1, .h1 {
  font-weight: 800;
  font-size: $h1-font-size;
  @include media-breakpoint-down(sm) {
    font-size: rem(30);
  }
}
h2, .h2 {
  font-weight: 700;
  font-size: $h2-font-size;
  @include media-breakpoint-down(sm) {
    font-size: rem(20);
  }
}
h3, .h3 {
  font-weight: 700;
  font-size: $h3-font-size;
  @include media-breakpoint-down(sm) {
    font-size: rem(18);
  }
}
h2, .h2 {
  font-weight: 700;
  font-size: $h2-font-size;
  @include media-breakpoint-down(sm) {
    font-size: rem(20);
  }
}
h3, .h3 {
  font-weight: 700;
  font-size: $h3-font-size;
  @include media-breakpoint-down(sm) {
    font-size: rem(18);
  }
}
h4, .h4 {
  font-weight: 700;
  font-size: $h3-font-size;
  @include media-breakpoint-down(sm) {
    font-size: rem(16);
  }
}
hr {
  border-color: $border-color;
}

button, 
input, 
optgroup, 
select, 
textarea, 
.form-control {
  font-size: $font-size-base;
}

.caret-right {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  &:before {
    content: "";
    display: block;
    border-style: solid;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-width: 0.3em 0 0.3em 0.3em;
  }
}
.elementor a.elementor-button,
.btn {
  font-size: rem(18);
  font-weight: 700;
  padding: 12px 20px;
  border-width: $btn-border-width;
  border-radius: $btn-border-radius;
  text-transform: initial;
  line-height: $btn-line-height;
  cursor: pointer;
  transition: all 0.3s;
  .sld-icon-arrow-right {
    display: inline-block;
    margin-left: 6px;
    font-size: .8em;
  }
  @include media-breakpoint-down(sm) {
    font-size: rem(16);
  }
}
.btn-link {
  padding: 0;
  &:focus,
  &.focus {
    text-decoration: none;
    span {
      text-decoration: underline solid #979797;
    }
  }
  &.hover,
  &:hover {
    text-decoration: none;
    span {
      text-decoration: underline solid $color_primary;
    }
  }
}
.btn-light {
  border: none;
  background-color: transparent;
}
button,
.btn,
.btn-primary,
.btn-secondary,
.btn-teriary {
  &:focus, &.focus,
  &:hover,
  &:active:focus, &.active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    outline: none;
    box-shadow: none;
  }
  &.disabled, &:disabled {
    pointer-events: none;
  }
}
.btn.btn-primary {
  @include my-button-variant(
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border,
    $btn-primary-color-hover,
    $btn-primary-bg-hover,
    $btn-primary-border-hover,
    $btn-primary-color-focus,
    $btn-primary-bg-focus,
    $btn-primary-border-focus
  );
}
.btn.btn-secondary {
  @include my-button-variant(
    $btn-secondary-color,
    $btn-secondary-bg,
    $btn-secondary-border,
    $btn-secondary-color-hover,
    $btn-secondary-bg-hover,
    $btn-secondary-border-hover,
    $btn-secondary-color-focus,
    $btn-secondary-bg-focus,
    $btn-secondary-border-focus
  );
}
.elementor-button-primary .elementor-button,
.elementor-button-secondary .elementor-button,
.btn.btn-primary,
.btn.btn-secondary {
  &.focus,
  &:focus {
    outline: solid 1px;
  }
}
.btn-teriary {
  border: 2px solid;
  color: $text-color;
  &:hover {
    color: $link-hover-color;
  }
}
.btn.btn-teriary {
  padding-top: $base-padding-border2px;
  padding-bottom: $base-padding-border2px;
  border: 2px solid;
  color: $text-color;
  &:hover {
    color: $link-hover-color;
  }
}
.btn.btn-small {
  font-size: 1em;
  padding: 5px 10px;
  font-weight: 400;
}
.btn.btn-large {
  font-size: 1.3em;
  padding-top: ($base-padding + 2);
  padding-bottom: ($base-padding + 2);
}
.btn-unstyle {
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: inherit;
  color: inherit;
  &:focus {
    outline: 0;
  }
}
.btn-wrap {
  white-space: normal;
}
.grid-small-padding {
  .row {
    margin-right: -10px;
    margin-left: -10px;
    *[class^="col"] {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
.title-block {
  @include make-title(50);
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
    justify-content: space-between;
    margin-bottom: 8px;
    .view-all-link {
      font-size: 0.75rem;
      padding-left: 0;
    }
  }
}
.dot-center-title {
  display: flex;
  align-items: center;
  span:not(.dot-line) {
    flex: none;
    margin-left: 2%;
    margin-right: 2%;
  }
  .dot-line {
    width: 50%;
    border-top: 1px dotted;
  }
}
.dot-title {
  display: flex;
  align-items: center;
  span:not(.dot-line) {
    flex: none;
    margin-right: 2%;
  }
  .dot-line {
    width: 100%;
    border-top: 1px dotted;
  }
}
.view-all-link {
  padding-left: 15px;
  font-size: 0.875rem;
  font-family: $text-font;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  &.at-bottom {
    display: none;
    padding-left: 0;
    margin-bottom: 40px;
    margin-top: -10px;
    text-align: center;
  }
  .material-icons.trending_flat:before {
    content: '\e8e4';
  }
}
.column-block {
  background: $column-block-background;
  padding: 0 15px 15px;
  border: 1px solid $border-color;
  border-radius: 5px;
  &.no-box {
    padding: 0;
    border: none;
    background: none;
  }
  &.no-title {
    padding-top: 15px;
  }
  .product-list-margin {
    margin: -15px;
    padding: 0 15px;
    border-radius: 0 0 4px 4px;
    .product-list-wrapper {
      margin: 0;
    }
  }
}
.column-title, .typo .column-title {
  @include make-title(17);
  margin: 0 -$box-padding $box-padding;
  padding: 8px $box-padding;
  border-bottom: 1px solid $border-color;
  a {
    color: $title-color;
  }
  .background-for-title & {
    background-color: $light-background;
    border-radius: 5px 5px 0 0;
  }
}

.hidden-index {
  display: block;
  #index & {
    display: none;
  }
}
.shown-index {
  display: none;
  #index & {
    display: block;
  }
}

.xs-bottom {
  margin-bottom: 10px;
}
.sm-bottom {
  margin-bottom: 20px;
}
.md-bottom {
  margin-bottom: 30px;
}
.lg-bottom {
  margin-bottom: 40px;
}
.xl-bottom {
  margin-bottom: 50px;
}

.page-heading {
  @include make-title(30);
}
.page-subheading {
  @include make-title(20);
  margin-bottom: 8px;
  text-transform: none;
}

.box-bg {
  background: $box-background;
  padding: $box-padding;
  border: 1px solid $box-background-darken;
  border-radius: 5px;
}
.light-box-bg {
  background: $box-background;
  padding: $box-padding;
  border: 1px solid $border-color;
  border-radius: 5px;
}
.shadow-box {
  background: $box-background;
  padding: $box-padding;
  box-shadow: 0 1px 5px $box-shadow-color;
  border-radius: 5px;
}
.card {
  background: $box-background;
  border: 1px solid $border-color;
  margin-bottom: 20px;
  border-radius: 5px;
}
.card-block {
  padding: .9375rem;
}

.linklist {
  @include media-breakpoint-down(sm) {
    border-top: 1px solid #FFFFFF;
    padding-top: 20px;
  }
  li {
    padding: 6px 0;
    a {
      
    }
  }
}

.linklist-title {
  font-size: rem(18);
  font-weight: 700;
  margin-bottom: 20px;
}
.icon-link {
  color: inherit;
  &:hover {
    color: $icon-color;
  }
}

.nav-tabs {
  border: none;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .nav-item {
    margin: 0 4px;
    .nav-link {
      padding: 3px 15px;
      font-size: rem(20);
      font-weight: 700;
      color: #000000;
      text-align: center;
      margin: 0;
      border: none;
      background: transparent;
      opacity: 0.5;
      &:hover, &:focus {
        background: transparent;
        opacity: 1;
      }
      &.active {
        border: none;
        opacity: 1;
        &:focus, &:hover {
          color: $title-color;
        }
      }
      @include media-breakpoint-down(sm) {
        padding-left: 5px;
        padding-right: 5px;
        font-size: rem(18);
        text-align: left;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
    .nav-item {
      margin-bottom: 4px;
    }
  }
}
.aone-tabs {
  .nav-tabs {
    margin-bottom: 15px;
    .nav-item {
      .nav-link {
        font-size: 1.375rem;
        line-height: 29px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    .background-for-title & {
      padding: 5px 0;
      background-color: $light-background;
      border-radius: 5px;
    }
  }
}

.dropdown-toggle {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  border-width: 0 0 1px 0;
  border-color: #B9BAB9;
  background-color: transparent;
  width: 100%;
  text-align: left;
  padding-bottom: 4px;
  &:after {
    content: '\e92a';
    font-family: $font_icon;
    font-size: 8px;
    border: none;
    position: absolute;
    z-index: -1;
    right: 0;
  }
  .check-shape {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fff;
    margin-right: 10px;
  }
  .check-circle {
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    width: 20px;
    height: 20px;
    border: 1px solid #040405;
    border-radius: 50%;
  }
}
.dropdown-toggle-text {
  font-weight: 600;
  font-size: rem(18);
  @include media-breakpoint-down(sm) {
    font-size: rem(16);
  }
}
.dropdown-menu {
  width: 100%;
  li {
    padding: 6px;
    &:nth-of-type(even) {
      background-color: #f2f2f2;
    }
    &.hover,
    &:hover {
      background-color: #b9bab9;
    }
  }
  .custom-radio {
    width: 100%;  
  }
}

label, .label {
  color: $title-color;
  text-align: right;
  font-weight: 700;
  margin-bottom: $base-padding;
  &[for] {
    cursor: pointer;
  }
  @include media-breakpoint-down(md) {
    text-align: left;
  }
}
small.label, small.value {
  font-size: $font-size-base;
  font-weight: 400;
}

.form-group {
  margin-bottom: 1.25rem;
  &.has-error {
    input,
    select {
      border-color: transparent;
      box-shadow: 0 0 0 1px $danger;
    }
  }
  .help-block {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 0.625rem;
  }
}
.form-control-label {
  padding-top: $base-padding;
  padding-bottom: $base-padding;
  margin: 0;
}
.form-control {
  padding-top: $base-padding-border1px;
  padding-bottom: $base-padding-border1px;
  border-radius: 5px;
  line-height: $input-line-height + rem;
  height: $base-height;
  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
  &.focucs,
  &:focus {
    outline: 1px solid $color_text;
  }
}
.input-wrapper {
  position: relative;
}
.input-btn {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  height: 50px;
  min-width: 50px;
  .btn-light {
    height: inherit;
    width: inherit;
    border-radius: 0 50px 50px 0;
  }
}
textarea.form-control {
  height: auto;
}
.form-control-valign {
  @include media-breakpoint-up(md) {
    padding-top: $base-padding;
  }
}

.input-group {
  .input-group-btn {
    outline: none;
    font-size: inherit;
    > .btn {
      box-shadow: none;
      margin-left: 0;
      padding: $base-padding 10px;
      background: $border-color;
      min-width: 60px;
      font-size: $font-size-base;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 100%;
    }
  } 
}
.form-control-select {
  appearance: none;
  background: $input-bg url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgd2lkdGg9IjQwLjE5MjAwMSIKICAgaGVpZ2h0PSIxOS45NzMiCiAgIHZpZXdCb3g9IjAgMCA0MC4xOTIwMDIgMTkuOTcyOTk5IgogICB2ZXJzaW9uPSIxLjEiCiAgIGlkPSJzdmczIgogICBzb2RpcG9kaTpkb2NuYW1lPSJjaGV2cm9uLWJvdHRvbS5zdmciCiAgIGlua3NjYXBlOnZlcnNpb249IjEuMS4xICgzYmY1YWUwZDI1LCAyMDIxLTA5LTIwKSIKICAgeG1sbnM6aW5rc2NhcGU9Imh0dHA6Ly93d3cuaW5rc2NhcGUub3JnL25hbWVzcGFjZXMvaW5rc2NhcGUiCiAgIHhtbG5zOnNvZGlwb2RpPSJodHRwOi8vc29kaXBvZGkuc291cmNlZm9yZ2UubmV0L0RURC9zb2RpcG9kaS0wLmR0ZCIKICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZGVmcwogICAgIGlkPSJkZWZzNyIgLz4KICA8c29kaXBvZGk6bmFtZWR2aWV3CiAgICAgaWQ9Im5hbWVkdmlldzUiCiAgICAgcGFnZWNvbG9yPSIjNTA1MDUwIgogICAgIGJvcmRlcmNvbG9yPSIjZWVlZWVlIgogICAgIGJvcmRlcm9wYWNpdHk9IjEiCiAgICAgaW5rc2NhcGU6cGFnZXNoYWRvdz0iMCIKICAgICBpbmtzY2FwZTpwYWdlb3BhY2l0eT0iMCIKICAgICBpbmtzY2FwZTpwYWdlY2hlY2tlcmJvYXJkPSIwIgogICAgIHNob3dncmlkPSJmYWxzZSIKICAgICBpbmtzY2FwZTp6b29tPSIyMC41MDE1OTIiCiAgICAgaW5rc2NhcGU6Y3g9IjE4LjQxMzIwNCIKICAgICBpbmtzY2FwZTpjeT0iOC4zMTY0Mjc1IgogICAgIGlua3NjYXBlOndpbmRvdy13aWR0aD0iMTkyMCIKICAgICBpbmtzY2FwZTp3aW5kb3ctaGVpZ2h0PSIxMDA5IgogICAgIGlua3NjYXBlOndpbmRvdy14PSItOCIKICAgICBpbmtzY2FwZTp3aW5kb3cteT0iNDYyIgogICAgIGlua3NjYXBlOndpbmRvdy1tYXhpbWl6ZWQ9IjEiCiAgICAgaW5rc2NhcGU6Y3VycmVudC1sYXllcj0ic3ZnMyIgLz4KICA8cGF0aAogICAgIGlkPSJmbGVjaGUiCiAgICAgZD0iTSAyLjgxNTUwMDcsOS41MzU5NTk4ZS00IDE3LjkxMTUwMSwxNS4wODc5NTQgYSAzLjExOCwtMy4xMTggMCAwIDAgNC40LDAgbCAwLjAxLDAuMDA5IDE1LjA1OCwtMTUuMDYzMDAwNCAyLjgxNCwyLjgxNCAtMTUuMDU3LDE1LjA1ODAwMDQgLTAuMDEsLTAuMDA3IGEgNy4xLC03LjEgMCAwIDEgLTEwLjAyOCwwIEwgLTAuMDAxNDk5MzMsMi44MTE5NTM2IFoiCiAgICAgZmlsbD0iIzA2MDYwNiIgLz4KPC9zdmc+Cg==") no-repeat scroll right 10px center / 20px 20px;
  background-size: 20px 25px;
  padding-right: 24px;
  font-weight: 600;
  &::-ms-expand {
    display: none;
  }
}
select.form-control {
  &:not([size]):not([multiple]) {
    height: $base-height;
  }
}

.form-control-comment {
  padding-top: $base-padding;
  font-style: italic;
}
.form-control-submit {
  &.disabled {
    background: $info;
    color: white;
  }
}

.group-span-filestyle {
  label {
    margin: 0;
  }
  .btn-default {
    background: $primary;
    padding: 8px 16px;
  }
}

.help-block {
  margin-top: $base-padding;
  margin-bottom: 1.25rem;
  .alert {
    padding: $base-padding;
    margin-bottom: 0;
  }
}
.alert {
  ul {
    list-style: square;
    padding-left: 20px;
  }
}

.bootstrap-touchspin {
  input {
    &:focus {
      outline: none;
    }
    &.form-control {
      border: $input-btn-border-width solid $input-border-color;
    }
  }
  .btn-touchspin {
    @extend .btn-default;
    background-color: $input-bg;
    border: $input-btn-border-width solid $input-border-color;
    height: 50%;
    &:hover {
      background-color: $gray-200;
    }
  }
  .input-group-btn-vertical {
    width: 20px;
    display: block;
    > .btn {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bootstrap-touchspin-up {
      border-top-right-radius: 5px;
    }
    .bootstrap-touchspin-down {
      border-bottom-right-radius: 5px;
      margin-top: 0;
      border-top: 0;
    }
    .touchspin-up {
      &:after {
        content: '\E5CE';
      }
    }
    .touchspin-down {
      &:after {
        content: '\E5CF';
      }
    }
    i {
      font-size: 14px;
      font-weight: 700;
      position: static;
    }
  }
}

$custom-input-width: 16px;
.custom-checkbox,
.custom-radio {
  margin: 0;
  text-transform: none;
  font-weight: 400;
  text-align: left;
  font-size: 100%;
  display: flex;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
  .check-wrap {
    position: relative;
    width: ($custom-input-width + 8px);
    flex: none;
  }
  .check-shape {
    display: block;
    width: $custom-input-width;
    height: $custom-input-width;
    border: 1px solid $border-color;
    background-color: $input-bg;
    .check-icon {
      display: none;
      font-size: 12px;
      padding-top: 1px;
      padding-left: 1px;
      font-weight: 700;
    }
  }
  label {
    font-weight: 400;
    margin: 0;
    text-align: initial;
    text-transform: none;
  }
}
.custom-checkbox input[type="checkbox"], 
.custom-radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  &.focus,
  &:focus {
    + .check-shape {
      border-color: $link-hover-color;
      border-width: 2px;
    }
  }
  &.hover,
  &:hover {
    + .check-shape {
      border-color: $link-hover-color;
    }
  }
  &:checked {
    + .check-shape {
      background-color: $link-hover-color;
      border-color: $link-hover-color;
      color: $light-text-color;
      .check-icon {
        display: block;
      }
    }
  }
}
.custom-radio input[type="radio"] + .check-shape {
  border-radius: 50%;
}
.custom-checkbox, .custom-radio {
  .check-shape.color {
    border-color: rgba(0, 0, 0, 0.05);
    background-size: contain;
    position: relative;
    .check-circle {
      display: none;
      position: absolute;
      left: -3px;
      top: -3px;
      width: $custom-input-width + 6;
      height: $custom-input-width + 6;
      border: 1px solid $link-hover-color;
      border-radius: 50%;
    }
  }
}
.custom-checkbox input[type="checkbox"], .custom-radio input[type="radio"] {
  &:hover + .check-shape.color {
    border-color: rgba(0, 0, 0, 0.05);
  }
  &:checked + .check-shape.color {
    border: 3px solid $light-background;
    border-radius: 50%;
    width: $custom-input-width + 6;
    height: $custom-input-width + 6;
    .check-circle {
      display: block;
    }
  }
}


.custom-select {
  position: relative;
  z-index: 0;
  height: auto;
  line-height: inherit;
  width: 100%;
  padding: $base-padding-border1px 24px $base-padding-border1px 10px;
  border-width: 0 0 1px 0;
  border-color: #000;
  &:focus {
    box-shadow: none;
  }
  &:after {
    content: '\e92a';
    font-family: $font_icon;
    font-size: 8px;
    position: absolute;
    z-index: -1;
    top: calc(50% - 8px);
    right: 10px;
  }
}

.text-muted {
  font-size: 0.875rem;
}
.done {
  color: $success;
  display: inline-block;
  padding: 0 5px;
  margin-right: 2%;
  text-align: center;
}
.definition-list {
  dl {
    display: flex;
    flex-wrap: wrap;
    dt {
      font-weight: normal;
    }
    dd,
    dt {
      flex: 0 0 45%;
      background: $gray-200;
      padding: 10px;
      margin: 2px;
      &:nth-of-type(even) {
        background: $gray-200;
      }
    }
  }
}
.alert-warning {
  .material-icons {
    //color: $warning;
    font-size: 2rem;
    margin-right: $small-space;
  }
  .alert-text {
    font-size: 0.9375rem;
    padding-top: $small-space;
  }
  .alert-link {
    border-radius: 2px;
    border-width: 2px;
    margin-left: $small-space;
    padding: $extra-small-space $medium-space;
    font-weight: 600;
    font-size: 0.8125rem;
    color: $btn-tertiary-color;
  }
  ul li:last-child .alert-link {
    color: white;
  }
  .warning-buttons {
    margin-top: $extra-small-space;
  }
}
.btn-tertiary-outline {
  color: $btn-tertiary-color;
  background-image: none;
  background-color: transparent;
  border-color: $btn-tertiary-color;
  border: 0.15rem solid $btn-tertiary-color;
  transition: all .4s ease-in-out;
  &:hover {
    border-color: $btn-tertiary-hover;
    color: $btn-tertiary-hover;
  }
}
.thead-default th {
  color: inherit;
  background-color: $box-background-darken;
}

.modal-dialog {
  margin-top: 3%;
  @include media-breakpoint-down(sm) {
    margin-top: 10%;
  }
  @include media-breakpoint-down(xs) {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}
.modal-content {
  border: none;
}
.modal-body {
  padding: ($grid-gutter-width / 2);
}
.modal-footer {
  border-top: 1px solid $border-color;
  padding: ($grid-gutter-width / 2);
  display: block;
  text-align: right;
}
.modal-title {
  line-height: inherit;
}
.modal-header {
  border-bottom: 1px solid $border-color;
  display: block;
  position: relative;
}
.modal .modal-header {
  .close {
    opacity: 1 !important;
    font-size: 18px;
    width: 20px;
    height: 20px;
    border-radius: 0 0.3rem;
    text-shadow: none;
    position: absolute;
    margin: 0;
    padding: 0;
    right: 0;
    top: 0;
    text-align: center;
    z-index: 10;
    &:hover {
      background: $icon-color;
      color: $light-text-color;
    }
  }
}
.modal-backdrop.show {
  opacity: 0.3;
}
.modal-message {
  .modal-header {
    border: none;
    padding: 0;
  }
  .modal-body {
    padding: 20px 10px 10px;
  }
}
.simple-modal {
  .modal-header {
    border: none;
    padding: 5px;
  }
  .extra-content {
    iframe {
      border: none;
      background: transparent url('#{$path_img}loading.gif') no-repeat 50% 50%;
    }
  }
}

.tooltip {
  font-size: 1rem;
  font-family: inherit;
}

.table {
  background: $box-background;
  thead th {
    border-bottom: $table-border-width solid $table-border-color;
  }
}
.table-bordered {
  thead {
    th,
    td {
      border-bottom-width: $table-border-width;
    }
  }
}

.img-thumbnail {
  border-color: $border-color-lighten;
  padding: 3px;
  a:hover & {
    filter: brightness(90%);
  }
}

.ui-autocomplete {
  &.ui-widget-content {
    border: 1px solid $border-color;
    background: $color_gray;
    border-radius: 5px;
    padding: 5px 0;
    font-size: $font-size-base;
    overflow: hidden;
  }
  &.ui-menu .ui-menu-item {
    a {
      padding: 5px 10px;
      line-height: inherit;
      border: none;
      background: none;
      color: $color_text;
      cursor: pointer;
    }
    .ui-state-focus, .ui-state-hover {
      background: $light-background;
      color: $link-hover-color;
      margin: 0;
    }
  }
}
