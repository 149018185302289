@import "../partials/variables";

.product-miniature {
  .variant-links-list {
    display: block;
    width: 100%;
    height: 26px;
    overflow: hidden;
    span.variant {
      position: relative;
      float: left;
      margin: 3px;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      border-radius: 50%;
      border: 2px solid transparent;
      cursor: pointer;
      .check-circle{
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px $color_primary solid;
      }
      &.border-grey{
        border-color: $color-text;
      }
      &.selected{
        border-radius: 50%;
        .check-circle{
          display: block;
        }
        &.border-grey{
          border-color: transparent;
        }
      }
    }
    .more{
      float: left;
      margin: 3px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: $color_primary;
      color: $color_white;
      border: 1px solid $color_primary;
      &:hover{
        background-color: $color_white;
        color: $color_primary;
      }
    }
  }
}
.product-from {
  color: rgba(#000, .66);
  text-align: center;
  margin-bottom: 6px;
}