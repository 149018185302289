//@import "_imageslider";
//@import "_mainmenu";
//@import "_featuredproducts";
@import "_block-reassurance";
@import "_search-widget";
@import "_ps_socialfollow";
@import "_ps_emailsubscription";
@import "_ps_sharebuttons";
@import "_ps_shoppingcart";
@import "_ps_customersignin";
@import "_aeuc_front";

.currency-selector-wrapper + .language-selector-wrapper,
.language-selector-wrapper + .currency-selector-wrapper {
  margin-left: 5px;
}
.currency-selector, 
.language-selector {
  .dropdown-current {
    padding: 5px 8px;
    background-color: $color_gray;
    border-radius: 3px;
  }
  .dropdown-icon {
    .material-icons, .fa {
      font-size: 15px;
    }
  }
  .dropdown-menu {
    min-width: 0;
  }
}
.language-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.js-language-source {
  #language-selector-label {
    display: none;
  }
  .l-code {
    display: none;
  }
  .l-name {
    display: flex;
    font-size: rem(18);
    @include media-breakpoint-down(sm) {
      font-size: rem(16);
    }
    font-weight: 600;
    padding-bottom: 2px;
  }
  img {
    margin-top: -2px;
  }
  .current .language-link {
    color: $color_primary;
    .footer & {
      color: #fff;
    }
  }
  .language-link {
    padding: 6px;
    color: #979797;
    text-underline-offset: 4px;
    &.focus,
    &:focus {
      text-decoration: underline solid rgba($color_primary, .4);
    }
    &.hover,
    &:hover {
      color: $color_primary;
      text-decoration: underline solid $color_primary;
    }
  }
  .language-item {
    display: flex;
    position: relative;
    padding-right: 4px;
    &+ .language-item {
      padding-left: 4px;
      &:before {
        content: '';
        display: block;
        width: 2px;
        height: 20px;
        background-color: #060606;
        position: absolute;
        z-index: 1;
        top: 6px;
        left: 1px;
        .footer & {
          background-color: #fff;
        }
      }
    }
  }
}
.currency-selector {
  #currency-selector-label {
    display: none;
  }
  .dropdown-menu {
  }
  .c-sign {
    display: inline-block;
  }
}
.sidebar-currency {
  border-top: 1px solid $border-color;
  padding: 20px 10px;
  .currency-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0 3px;
    }
    .dropdown-item {
      border-radius: 5px;
      padding: 5px 8px;
    }
    .current .dropdown-item {
      background: $color_green;
      color: $light-text-color;
    }
  }
  .c-sign {
    display: none;
  } 
}
.sidebar-language {
  border-top: 1px solid $border-color;
  padding: 20px 10px;
  .l-name {
    display: none;
  }
  .language-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0 3px;
    }
    .dropdown-item {
      border-radius: 5px;
      padding: 5px 8px;
      text-transform: uppercase;
      min-width: 40px;
      text-align: center;
    }
    .current .dropdown-item {
      background: $color_green;
      color: $light-text-color;
    }
  }
}

.sidebar-account {
  padding: 20px 10px;
  border-top: 1px solid $border-color;
  background: $color_gray;
  &.user-info {
    > ul >li {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .account-link {
    }
    .logout-link {
      display: block;
      color: red;
    }
    .dropdown-customer-account-links {
      display: none;
    }
  }
}
.sidebar-header-phone {
  padding: 15px 5px;
  border-top: 1px solid $border-color;
  background: $color_gray;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      white-space: nowrap;
      padding: 8px 12px;
    }
  }
}
.st-menu-title {
  background: $color_gray;
}
.sidebar-menu {
  padding: 15px;
  border-top: 1px solid $border-color;
  .waiting-load-categories {
    height: 50px;
    background: transparent url('#{$path_img}loading.gif') no-repeat 50% 50%;
  }
}
.sidebar-category-tree.category-tree {
  .category-sub-menu {
    display: none;
  }
}

#footer {
  .linklist {
    a {
      i {
        display: inline-block;
        margin-right: 6px;
      }
      &.focus,
      &:focus {
        color: $link-hover-color;
      }
      &.hover,
      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
}

.mailalert-product-page {
  .mailalert-form {
    margin: 0 -8px;
    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .alert-email {
    width: 100%;
    padding: 0 8px;
  }
  .alert-button {
    padding: 0 8px;
    .btn {
      text-transform: none;
      padding-left: 15px;
      padding-right: 15px;
      white-space: nowrap;
    }
  }
  .mailalert-msg {
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    .mailalert-form {
      flex-direction: column;
    }
    .alert-email {
      margin-bottom: 5px;
    }
  }
}

.my-mail-alerts {
  padding-top: 0;
  padding-bottom: 0;
  .mail-alert-line {
    padding: $box-padding 0;
    + .mail-alert-line {
      border-top: 1px solid $border-color-lighten;
    }
    .p-image img {
      max-width: 80px;
    }
    .p-name {
      margin-left: 10px;
    }
    .p-remove {
      margin-left: auto;
    }
  }
}

#module-prestablog-blog{
  #blog_list_1-7_new{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }
    .block_cont{
      padding: 20px;
      border-radius: 11px 0 11px 0;
      border: solid 1px $color_primary;
    }
    .block_top{
      margin-bottom: 20px;
      img{
        width: 100%;
        border-radius: 11px 0 11px 0;
      }
    }
    a.blog_link{
      text-decoration: underline;
      font-weight: 700;
      color: $color_primary;
    }
  }
}
